import styled from 'styled-components'
import { Link } from 'gatsby'

export const MainSection = styled.div`
    margin-top: -50px;
    margin-bottom: 40px;
    z-index: 2000;
    position: relative;

    @media (max-width: 1024px) {
        margin-top: 30px;
    }

`

export const MainContainer = styled.div`
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
`

export const MainImage = styled.img`
    width: 100%;
    max-height: 500px;
    -o-object-fit: cover;
    object-fit: cover;
`

export const USP = styled.div`
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    vertical-align: top;
    margin-bottom: 30px;
`

export const USPContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`

export const USPHeading = styled.h1`
    font-size: 20px;
`

export const USPText = styled.div`
    font-family: Ubuntu, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 29px;
    margin-bottom: 5px;
`



export const IconContainer = styled.span`
    margin: 0;
    max-width: 47px;
    text-align: center;
    border: 0;
    font-family: inherit;
    font-size: 48px;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
    color: #db002e;
`

export const ServiceTextContainer = styled.div`
    margin-top: 0;
    
    text-align: left;
    width: 100%;
    align-self: flex-start;
    padding-left: 30px;

`

export const ServiceContainer = styled(USPContainer)`
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 60px;
    width: 33.3%;
    @media (max-width: 1024px) {
        width: 50%;
    }

    @media (max-width: 768px) {
        width: 100%;
    }

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row !important;

`



export const ServiceSection = styled.div`
    background-color: #fafafa !important;
    padding-top: 80px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

`

export const ServiceMainContainer = styled(MainContainer)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
`

export const ServiceHeading = styled.h3`
    margin-top: 0;
`

export const ReferenceSection = styled.div`
    background-color: #383f4b;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
`

export const ReferenceHeader = styled.h2`
    font-size: 34px;
    color: white;
    margin-bottom: 20px;
`

export const ReferenceOverlay = styled.div`
    height: 100px;
    background-color: #db002e;
    bottom: 0;
    position: absolute; 
    width: 100%;
    padding-left: 40px;
`

export const SlideH3 = styled.h3`
    color: white;
    font-size: 20px;
    line-height: 20px;
`

export const SlideTextSmall = styled.p`
    color: white;
    font-size: 16px;
    font-weight: 400;
`

export const CTASection = styled.div`
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    background-color: #db002e;
    color: white;
`

export const CTAButton = styled(Link)`
    margin-top: 30px;
    color: white;
    font-size: 18px;
    padding: 20px;
    background:  transparent;
    height:  50px;
    line-height:  40px;
    border:  5px solid white;
    display:  inline-block;
    text-align:  center;
    width:  150px;
    padding:  0px!important;
    text-decoration: none;

    &:hover {
        background:  white;
        color: black;
        font-weight: 600;
    }
`

export const TestimonialSection = styled.div`
    width: 100%;
    margin-top: 80px;
    padding-bottom: 80px;
    text-align: center;
`

export const TestimonialText = styled.p`
    font-size: 28px;
    line-height: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 400;
    font-family: Ubuntu;
`

export const TestimonialName = styled.p`
    font-size: 14px;
`

export const TextSlide = styled.div`
    width: 100%;
`

export const SliderContainer = styled.div`

`