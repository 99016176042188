/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { globalHistory } from "@reach/router"



const SEO = ({ seo, title }) =>  {  
  const {image, description} = seo
  const metaDescription = description
  const defaultImage = "https://cdn.sanity.io/images/j4dvurzs/production/b28095b94fe914d4f5c5cd2bab362d0d5a7d763f-2048x1200.png?w=1200&h=630"
  const lang = "no"

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s - Murergutta`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image ? `${image.asset.url}?w=1200&h=630` : defaultImage,
        },
        {
          property: `og:url`,
          content: globalHistory.location.href,
        },


        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  )
}


SEO.propTypes = {
  seo: PropTypes.object
}

export default SEO
